body {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(354deg, rgba(87,0,145,1) 0%, rgba(109,1,209,1) 50%, rgba(244,69,252,1) 100%);
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: rgb(210, 210, 210);
}

h1 {
  font-size: 32px;
  margin-bottom: 40px;
  margin-top: 0px;
}

h1.hm {
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  margin: 0;
}

input, textarea{
  margin-top: 60px;
  border: 2px solid white;
  border-radius: 6px;
  background-color: rgba(0,0,0,0);
  color: white;
  font-size: 18px;
  padding: 8px 15px;
}

textarea {
  min-height: 100px;
  min-width: 300px;
}

.App {
  padding: 20px;
}

.question {
  margin-bottom: 0;
}

.nm {
  margin: 0;
}

.m-t-20{
  margin-top: 20px;
}

.m-t-40{
  margin-top: 40px;
}

.m-t-60{
  margin-top: 60px;
}

.inline {
  display: inline;
}

.inline-block{
  display: inline-block;
}

.flex {
  display: flex;
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.gap-def {
  gap: 20px
}

.col {
  flex-direction: column;
}

.fullheight{
  height: calc(100vh - 40px);
  max-height: 100%;
}

.halfwidth {
  max-width: 100%;
}

@media screen and (min-width: 700px) {
  .halfwidth {
    max-width: 50vw;
  }
}

.button {
  color: #fff;
  text-decoration: none;
  padding: 20px 60px;
  border: 2px solid #fff;
  border-radius: 6px;
  font-size: 24px;
  width: fit-content;
  cursor: pointer;
  background-color: rgba(0,0,0,0);
}

.text-center {
  text-align: center;
}

.select-container {
  width: 100%;
  margin-top: 40px;
}

@media screen and (min-width: 700px) {
  .select-container {
    width: 450px;
  }
}

.result{
  margin-top: 40px;
  margin-bottom: 40px;
}

/* LOADER */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin-top: 40px;
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: rgba(87,0,145,1);
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}